import { Box, Container, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import {
  Suspense,
  useEffect,
  useCallback,
  useMemo,
  useState,
  ReactElement,
} from 'react';
import TagManager from 'react-gtm-module';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import { ResultItemCard } from '@app/components/Product/ResultItemCard';
import { SearchCondition } from '@app/components/Search/SearchCondition';
import { SearchConditionSummary } from '@app/components/Search/SearchConditionSummary';
import { SearchNoResult } from '@app/components/Search/SearchNoResult';
import { SearchTypeTab } from '@app/components/Search/SearchTypeTab';
import { BottomMenu } from '@app/components/Shared/BottomMenu';
import { Breadcrumb } from '@app/components/Shared/Breadcrumb';
import {
  getProductsDescription,
  HeadBlock,
} from '@app/components/Shared/HeadBlock';
import { Loading } from '@app/components/Shared/Loading';
import { ScrollThreshold } from '@app/components/Shared/ScrollThreshold';
import {
  locationsByPrefectureSelector,
  searchConditionState,
  searchResultProductsState,
} from '@app/domain/catalog';
import { getProducts } from '@app/domain/network-actions';
import { useDeviceType } from '@app/hooks/useBrowserHooks';
import { useGADataLayer } from '@app/hooks/useGADataLayer';
import { useSetSnackbar } from '@app/hooks/useSetSnackbar';
import { BOTTOM_MENU_ITEMS, GA_CUSTOM_EVENT } from '@app/static/constants';
import { theme } from '@app/theme';
import { CategoryName, CategoryType, Conditions } from '@app/types/catalog';
import { LoadableState } from '@app/types/common';
import { getSearchResultUrl } from '@app/utils/catalog';
import { generateEventName } from '@app/utils/event';

const breadcrumbItems = [
  { label: 'トップページ', link: '/' },
  { label: '検索結果' },
];

export function Index(): ReactElement {
  const navigate = useNavigate();
  const location = useLocation();
  const setSnackbar = useSetSnackbar();
  const [conditionState, setConditionState] =
    useRecoilState(searchConditionState);
  const [resultProducts, setResultProducts] = useRecoilState(
    searchResultProductsState
  );
  const [loadable, setLoadable] = useState<LoadableState>(
    LoadableState.HAS_VALUE
  );
  const prefectures = useRecoilValue(locationsByPrefectureSelector);
  const { isMobile } = useDeviceType();
  const { sendGAEvent } = useGADataLayer();

  const getParamToString = (value: string | null) => {
    return value ? value.split(',') : undefined;
  };

  const searchCondition = useMemo(() => {
    const queryParams = new URLSearchParams(location.search);

    const floorPlanRoomsString = queryParams.get('floorPlanRooms');
    const floorPlanRooms = floorPlanRoomsString
      ? floorPlanRoomsString.split(',').map((value) => parseInt(value, 10))
      : [];

    return {
      access: queryParams.get('access') || '',
      childrenIds: getParamToString(queryParams.get('child')) || [],
      floorPlanRooms,
      locationIds: getParamToString(queryParams.get('locations')) || [],
      maxBuildingArea: queryParams.get('maxBuildingArea') || '',
      maxLandArea: queryParams.get('maxLandArea') || '',
      maxPrice: queryParams.get('maxPrice') || '',
      minBuildingArea: queryParams.get('minBuildingArea') || '',
      minLandArea: queryParams.get('minLandArea') || '',
      minPrice: queryParams.get('minPrice') || '',
      organizationId: getParamToString(queryParams.get('org')) || [],
    };
  }, [location.search]);

  const headInfo = useMemo(() => {
    const prefecture =
      searchCondition.locationIds.length === 1
        ? prefectures?.find((p) => p.id === searchCondition.locationIds[0])
            ?.name
        : undefined;
    const paramLength = Array.from(
      new URLSearchParams(location.search).keys()
    ).length;

    if (paramLength > 2 || (!prefecture && paramLength > 1)) {
      return {};
    }
    return {
      description: getProductsDescription(prefecture),
    };
  }, [location.search, prefectures, searchCondition]);

  const submitSearch = useCallback(
    (data?: Conditions) => {
      const conditions = {
        ...searchCondition,
        ...data,
        access: data?.access !== undefined ? String(data.access) : undefined,
        maxBuildingArea:
          data?.maxBuildingArea !== undefined
            ? String(data.maxBuildingArea)
            : undefined,
        maxLandArea:
          data?.maxLandArea !== undefined
            ? String(data.maxLandArea)
            : undefined,
        maxPrice:
          data?.maxPrice !== undefined ? String(data.maxPrice) : undefined,
        minBuildingArea:
          data?.minBuildingArea !== undefined
            ? String(data.minBuildingArea)
            : undefined,
        minLandArea:
          data?.minLandArea !== undefined
            ? String(data.minLandArea)
            : undefined,
        minPrice:
          data?.minPrice !== undefined ? String(data.minPrice) : undefined,
      };
      setConditionState(null);
      globalThis.scrollTo(0, 0);
      navigate(getSearchResultUrl(conditions));

      sendGAEvent(GA_CUSTOM_EVENT.SEARCH_RESULT_SEARCH, isMobile);
    },
    [isMobile, navigate, searchCondition, sendGAEvent, setConditionState]
  );

  const tabName = useMemo(() => {
    if (location.pathname.includes('/exhibitions')) {
      return CategoryName.EXHIBITION;
    } else if (location.pathname.includes('/properties')) {
      return CategoryName.PROPERTY;
    }
    return CategoryName.PROPERTY;
  }, [location.pathname]);

  const fetchProducts = useCallback(
    async (nextLink?: string) => {
      if (loadable === LoadableState.LOADING) return;
      setLoadable(LoadableState.LOADING);
      try {
        const searchParams = searchCondition;

        const options = {
          ...searchCondition,
          access: searchParams.access ? Number(searchParams.access) : undefined,
          category: CategoryType.PROPERTY,
          childrenIds: searchParams.childrenIds,
          // NOTE: 検索結果に画像表示するimages、建築エリアを絞り込むlocations、価格や面積を絞り込むvariantsのexpand
          expand: 'variants,images,locations',
          maxBuildingArea: searchParams.maxBuildingArea
            ? Number(searchParams.maxBuildingArea)
            : undefined,
          maxLandArea: searchParams.maxLandArea
            ? Number(searchParams.maxLandArea)
            : undefined,
          maxPrice: searchParams.maxPrice
            ? Number(searchParams.maxPrice)
            : undefined,
          minBuildingArea: searchParams.minBuildingArea
            ? Number(searchParams.minBuildingArea)
            : undefined,
          minLandArea: searchParams.minLandArea
            ? Number(searchParams.minLandArea)
            : undefined,
          minPrice: searchParams.minPrice
            ? Number(searchParams.minPrice)
            : undefined,
          nextLink,
        };

        const result = await getProducts(options);

        setConditionState(searchCondition);
        setResultProducts({
          data: nextLink
            ? [...resultProducts.data, ...result.data.value]
            : result.data.value,
          nextLink: result.data['@nextLink'],
          total: result.data.total,
        });
        setLoadable(LoadableState.HAS_VALUE);
      } catch (error) {
        setLoadable(LoadableState.HAS_ERROR);
        setSnackbar(true, '求人の取得に失敗しました');
      }
    },
    [
      loadable,
      searchCondition,
      resultProducts.data,
      setConditionState,
      setSnackbar,
      setResultProducts,
    ]
  );

  useEffect(() => {
    if (!conditionState) {
      setResultProducts({
        data: [],
        nextLink: '',
        scrollY: 0,
        total: 0,
      });
      void fetchProducts();
    } else {
      globalThis.scrollTo(0, resultProducts?.scrollY || 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchCondition]);

  const handleScrollThresholdReached = async () => {
    if (_.isEmpty(resultProducts.nextLink)) {
      return;
    }
    await fetchProducts(resultProducts.nextLink);
  };

  const onChangeTab = useCallback(
    (selectedTab: string) => {
      const conditions: Conditions = {
        ...searchCondition,
        access: searchCondition.access
          ? Number(searchCondition.access)
          : undefined,
        maxBuildingArea: searchCondition.maxBuildingArea
          ? Number(searchCondition.maxBuildingArea)
          : undefined,
        maxLandArea: searchCondition.maxLandArea
          ? Number(searchCondition.maxLandArea)
          : undefined,
        maxPrice: searchCondition.maxPrice
          ? Number(searchCondition.maxPrice)
          : undefined,
        minBuildingArea: searchCondition.minBuildingArea
          ? Number(searchCondition.minBuildingArea)
          : undefined,
        minLandArea: searchCondition.minLandArea
          ? Number(searchCondition.minLandArea)
          : undefined,
        minPrice: searchCondition.minPrice
          ? Number(searchCondition.minPrice)
          : undefined,
      };

      submitSearch(conditions);
    },
    [searchCondition, submitSearch]
  );

  const handleClickProduct = useCallback(
    (id: string) => {
      setResultProducts({ ...resultProducts, scrollY: window.scrollY });
      navigate(`/properties/${id}`);
      TagManager.dataLayer({
        dataLayer: {
          event: generateEventName(
            GA_CUSTOM_EVENT.SEARCH_RESULT_VIEW_PROPERTIES,
            isMobile
          ),
        },
      });
    },
    [isMobile, navigate, resultProducts, setResultProducts]
  );

  return (
    <>
      <HeadBlock {...headInfo} />
      <Box data-e2e="searchResult-body">
        <Stack spacing={1} sx={{ bgcolor: 'secondary.main' }}>
          <Breadcrumb items={breadcrumbItems} />
          <Box>
            <SearchTypeTab value={tabName} onChangeTab={onChangeTab} />
            <Stack
              direction={isMobile ? 'column' : 'row'}
              justifyContent="space-between"
            >
              <Box>
                <Suspense fallback={<Loading />}>
                  {isMobile ? (
                    <SearchConditionSummary
                      condition={{
                        ...searchCondition,
                        access: searchCondition.access
                          ? Number(searchCondition.access)
                          : undefined,
                        maxBuildingArea: searchCondition.maxBuildingArea
                          ? Number(searchCondition.maxBuildingArea)
                          : undefined,
                        maxLandArea: searchCondition.maxLandArea
                          ? Number(searchCondition.maxLandArea)
                          : undefined,
                        maxPrice: searchCondition.maxPrice
                          ? Number(searchCondition.maxPrice)
                          : undefined,
                        minBuildingArea: searchCondition.minBuildingArea
                          ? Number(searchCondition.minBuildingArea)
                          : undefined,
                        minLandArea: searchCondition.minLandArea
                          ? Number(searchCondition.minLandArea)
                          : undefined,
                        minPrice: searchCondition.minPrice
                          ? Number(searchCondition.minPrice)
                          : undefined,
                      }}
                      onSearch={submitSearch}
                      showPropertySearch={true}
                    />
                  ) : (
                    <SearchCondition
                      condition={{
                        ...searchCondition,
                        access: searchCondition.access
                          ? Number(searchCondition.access)
                          : undefined,
                        maxBuildingArea: searchCondition.maxBuildingArea
                          ? Number(searchCondition.maxBuildingArea)
                          : undefined,
                        maxLandArea: searchCondition.maxLandArea
                          ? Number(searchCondition.maxLandArea)
                          : undefined,
                        maxPrice: searchCondition.maxPrice
                          ? Number(searchCondition.maxPrice)
                          : undefined,
                        minBuildingArea: searchCondition.minBuildingArea
                          ? Number(searchCondition.minBuildingArea)
                          : undefined,
                        minLandArea: searchCondition.minLandArea
                          ? Number(searchCondition.minLandArea)
                          : undefined,
                        minPrice: searchCondition.minPrice
                          ? Number(searchCondition.minPrice)
                          : undefined,
                      }}
                      onSearch={submitSearch}
                      showPropertySearch={true}
                    />
                  )}
                </Suspense>
              </Box>
              <Box flex={2} px={1}>
                <Container sx={{ py: 2 }}>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
                      検索結果
                    </Typography>
                    <Typography color={theme.palette.neutral.greyDark}>
                      {resultProducts.total}件
                    </Typography>
                  </Stack>
                </Container>
                {loadable !== LoadableState.LOADING &&
                  resultProducts.data.length === 0 && <SearchNoResult />}
                <Stack
                  alignItems="center"
                  spacing={1}
                  px={1}
                  sx={{
                    bgcolor: 'secondary.main',
                    mb: 10,
                  }}
                >
                  {loadable === LoadableState.LOADING && (
                    <Loading title={`検索結果(${resultProducts.total})`} />
                  )}
                  {resultProducts.data.map((product, index) => (
                    <ResultItemCard
                      key={product.id + index}
                      product={product}
                      onClick={() => handleClickProduct(product.id)}
                      showPropertyDetails={true}
                    />
                  ))}
                </Stack>
                <ScrollThreshold
                  disabled={_.isEmpty(resultProducts.nextLink)}
                  thresholdReached={handleScrollThresholdReached}
                />
              </Box>
            </Stack>
          </Box>
        </Stack>
        <BottomMenu menuItems={BOTTOM_MENU_ITEMS} />
      </Box>
    </>
  );
}
