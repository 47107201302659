import TagManager from 'react-gtm-module';

import { generateEventName } from '@app/utils/event';

export const useGADataLayer = () => {
  const sendGAEvent = (eventName: string, isMobile = false) => {
    TagManager.dataLayer({
      dataLayer: {
        event: generateEventName(eventName, isMobile),
      },
    });
  };

  return { sendGAEvent };
};
