import { Box } from '@mui/material';
import { ReactElement, useCallback, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { SearchCondition } from '@app/components/Search/SearchCondition';
import { BottomMenu } from '@app/components/Shared/BottomMenu';
import { Breadcrumb } from '@app/components/Shared/Breadcrumb';
import {
  searchConditionState,
  searchResultOrganizationsState,
  searchResultProductsState,
} from '@app/domain/catalog';
import { useGADataLayer } from '@app/hooks/useGADataLayer';
import { BOTTOM_MENU_ITEMS, GA_CUSTOM_EVENT } from '@app/static/constants';
import { Conditions } from '@app/types/catalog';
import { getSearchResultUrl } from '@app/utils/catalog';

// Hack: views/Product/Exhibitions/index.tsx および views/Product/Properties/index.tsx と共通処理が多いため、余力がある際はリファクタリングを行いたい。

/**
 * 展示場検索カードコンポーネントを返す
 * @returns 展示場検索カード
 */
function SearchExhibition(): ReactElement {
  const navigate = useNavigate();
  const location = useLocation();
  const [conditionState, setConditionState] =
    useRecoilState(searchConditionState);
  const [organizations, setOrganizations] = useRecoilState(
    searchResultOrganizationsState
  );

  const { sendGAEvent } = useGADataLayer();

  const getParamToString = (value: string | null) => {
    return value ? value.split(',') : undefined;
  };

  const searchCondition = useMemo(() => {
    const queryParams = new URLSearchParams(location.search);
    return {
      childrenIds: getParamToString(queryParams.get('child')) || [],
      locationIds: getParamToString(queryParams.get('locations')) || [],
      organizationId: getParamToString(queryParams.get('org')) || [],
    };
  }, [location.search]);

  const submitSearch = useCallback(
    (data?: Conditions) => {
      const conditions = {
        ...searchCondition,
        ...data,
        access: data?.access !== undefined ? String(data.access) : undefined,
        maxBuildingArea:
          data?.maxBuildingArea !== undefined
            ? String(data.maxBuildingArea)
            : undefined,
        maxLandArea:
          data?.maxLandArea !== undefined
            ? String(data.maxLandArea)
            : undefined,
        maxPrice:
          data?.maxPrice !== undefined ? String(data.maxPrice) : undefined,
        minBuildingArea:
          data?.minBuildingArea !== undefined
            ? String(data.minBuildingArea)
            : undefined,
        minLandArea:
          data?.minLandArea !== undefined
            ? String(data.minLandArea)
            : undefined,
        minPrice:
          data?.minPrice !== undefined ? String(data.minPrice) : undefined,
      };
      setConditionState(null);
      globalThis.scrollTo(0, 0);
      navigate(getSearchResultUrl(conditions));
      sendGAEvent(GA_CUSTOM_EVENT.SEARCH_RESULT_SEARCH, true);
    },
    [navigate, searchCondition, sendGAEvent, setConditionState]
  );

  useEffect(() => {
    if (!conditionState) {
      setOrganizations({
        data: [],
        nextLink: '',
        scrollY: 0,
        total: 0,
      });
    } else {
      globalThis.scrollTo(0, organizations?.scrollY || 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchCondition]);

  return (
    <SearchCondition
      condition={searchCondition}
      onSearch={submitSearch}
      showPropertySearch={false}
    />
  );
}

/**
 * 分譲検索カードコンポーネントを返す
 * @returns 分譲検索カード
 */
function SearchProperty(): ReactElement {
  const navigate = useNavigate();
  const location = useLocation();
  const [conditionState, setConditionState] =
    useRecoilState(searchConditionState);
  const [resultProducts, setResultProducts] = useRecoilState(
    searchResultProductsState
  );
  const { sendGAEvent } = useGADataLayer();

  const getParamToString = (value: string | null) => {
    return value ? value.split(',') : undefined;
  };

  const searchCondition = useMemo(() => {
    const queryParams = new URLSearchParams(location.search);

    const floorPlanRoomsString = queryParams.get('floorPlanRooms');
    const floorPlanRooms = floorPlanRoomsString
      ? floorPlanRoomsString.split(',').map((value) => parseInt(value, 10))
      : [];

    return {
      access: queryParams.get('access') || '',
      childrenIds: getParamToString(queryParams.get('child')) || [],
      floorPlanRooms,
      locationIds: getParamToString(queryParams.get('locations')) || [],
      maxBuildingArea: queryParams.get('maxBuildingArea') || '',
      maxLandArea: queryParams.get('maxLandArea') || '',
      maxPrice: queryParams.get('maxPrice') || '',
      minBuildingArea: queryParams.get('minBuildingArea') || '',
      minLandArea: queryParams.get('minLandArea') || '',
      minPrice: queryParams.get('minPrice') || '',
      organizationId: getParamToString(queryParams.get('org')) || [],
    };
  }, [location.search]);

  const submitSearch = useCallback(
    (data?: Conditions) => {
      const conditions = {
        ...searchCondition,
        ...data,
        access: data?.access !== undefined ? String(data.access) : undefined,
        maxBuildingArea:
          data?.maxBuildingArea !== undefined
            ? String(data.maxBuildingArea)
            : undefined,
        maxLandArea:
          data?.maxLandArea !== undefined
            ? String(data.maxLandArea)
            : undefined,
        maxPrice:
          data?.maxPrice !== undefined ? String(data.maxPrice) : undefined,
        minBuildingArea:
          data?.minBuildingArea !== undefined
            ? String(data.minBuildingArea)
            : undefined,
        minLandArea:
          data?.minLandArea !== undefined
            ? String(data.minLandArea)
            : undefined,
        minPrice:
          data?.minPrice !== undefined ? String(data.minPrice) : undefined,
      };
      setConditionState(null);
      globalThis.scrollTo(0, 0);
      navigate(getSearchResultUrl(conditions));
      sendGAEvent(GA_CUSTOM_EVENT.SEARCH_RESULT_SEARCH, true);
    },
    [navigate, searchCondition, sendGAEvent, setConditionState]
  );

  useEffect(() => {
    if (!conditionState) {
      setResultProducts({
        data: [],
        nextLink: '',
        scrollY: 0,
        total: 0,
      });
    } else {
      globalThis.scrollTo(0, resultProducts?.scrollY || 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchCondition]);

  return (
    <SearchCondition
      condition={{
        ...searchCondition,
        access: searchCondition.access
          ? Number(searchCondition.access)
          : undefined,
        maxBuildingArea: searchCondition.maxBuildingArea
          ? Number(searchCondition.maxBuildingArea)
          : undefined,
        maxLandArea: searchCondition.maxLandArea
          ? Number(searchCondition.maxLandArea)
          : undefined,
        maxPrice: searchCondition.maxPrice
          ? Number(searchCondition.maxPrice)
          : undefined,
        minBuildingArea: searchCondition.minBuildingArea
          ? Number(searchCondition.minBuildingArea)
          : undefined,
        minLandArea: searchCondition.minLandArea
          ? Number(searchCondition.minLandArea)
          : undefined,
        minPrice: searchCondition.minPrice
          ? Number(searchCondition.minPrice)
          : undefined,
      }}
      onSearch={submitSearch}
      showPropertySearch={true}
    />
  );
}

/**
 * カテゴリーに基づき検索カードコンポーネントを返す
 * @returns 検索カード
 */
export function Search(): ReactElement {
  /**
   * 現在のURL
   */
  const location = useLocation();
  /**
   * クエリパラメーター
   */
  const queryParams = new URLSearchParams(location.search);
  /**
   * パンくずリストのアイテム
   */
  const breadcrumbItems = [
    { label: 'トップページ', link: `/` },
    {
      label: '検索結果',
      link: `${location.pathname.replace(
        '/search',
        ''
      )}?${queryParams.toString()}`,
    },
    { label: '検索条件' },
  ];

  return (
    <Box>
      <Box display="flex" flexDirection="column" gap={4} mb={4}>
        <Breadcrumb items={breadcrumbItems} />
        {location.pathname.includes('/exhibitions') ? (
          <SearchExhibition />
        ) : (
          <SearchProperty />
        )}
      </Box>
      <BottomMenu menuItems={BOTTOM_MENU_ITEMS} />
    </Box>
  );
}
